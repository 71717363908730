/**
 * Created by pc on 4/7/18. */
import axios from "axios";

import ClientSession from "./client-session.js";
import Api from "./api.js";
import endpoints from "./endpoints.js";
let moment = require("moment");

// let API_BASE_URL = 'https://vere-api.herokuapp.com/api/';
// let API_BASE_URL = "http://173.249.18.184:7878/api/";
let API_BASE_URL = "http://localhost:8000/";
// let API_BASE_URL = "http://192.168.1.153:7878/api/";
// let API_BASE_URL = "http://192.168.1.106:7878/api/";
export var bet_groups = {}

export class ConstFunction {
  static translate = (modelObj, locale, all_locales) => {
    let lang_locale = modelObj['name']

    for (var index in modelObj['locales']) {
      let curLocale = all_locales[modelObj['locales'][index]['locale']]
      if (curLocale && curLocale['shortcode'] == locale)
        lang_locale = modelObj['locales'][index]['translation']
    }

    return lang_locale

  }

  static translate_promo_description_title = (promo_description, locale) => {

    let lang_locale = promo_description['title']

    for (var index in promo_description['title_locales']) {
      let curLocale = promo_description['title_locales'][index]['locale']
      if (curLocale && curLocale['shortcode'] == locale)
        lang_locale = promo_description['title_locales'][index]['translation']
    }

    return lang_locale

  }

  static translate_promo_description_description = (promo_description, locale) => {
    let lang_locale = promo_description['description']
    for (var index in promo_description['description_locales']) {
      let curLocale = promo_description['description_locales'][index]['locale']
      if (curLocale && curLocale['shortcode'] == locale)
        lang_locale = promo_description['description_locales'][index]['translation']
    }

    return lang_locale

  }

  static translate_match = (match_obj, locale, type = 'home') => {

    let attribute_name = type == 'home' ? 'hom' : 'awy'
    let default_attribute_name = attribute_name

    if (locale != 'en') {
      attribute_name = type == 'home' ? 'hometeam_locale' : 'awayteam_locale'

    }
    return match_obj[attribute_name] || match_obj[default_attribute_name]

  }

}

//  
export class OfflineTicket {
  constructor(data) {
    this.coupon = new Ticket(data['coupon'])
    this.created_at = moment(data['created_at'])
    this.is_paid = data['is_paid']
    this.ticketID = data['ticketID']
    this.paid_amount = data['paid_amount']
    this.cashout_price = data['cashout_price']
    this.cashout_avail = data['cashout_avail']
    this.status = data['status']
  }
}

export class Ticket {
  constructor(data) {
    this.couponID = data['couponID']
    this.ticketID = data['ticketID']
    this.stake = data['stake']
    this.printed = data['printed']
    this.status = data['status']
    this.cashout_avail = data['cashout_avail']
    this.cashout_price = data['cashout_price']
    this.is_paid = data['is_paid']
    this.created_at = moment(data['created_at'])
    this.possible_win = parseFloat(data['possible_win']).toFixed(2)
    this.ticket = data['ticket'] ? true : false
    this.odds = []

    for (var i = 0; i < data['game_picks'].length; i++) {
      this.odds.push(new TicketOdd(data['game_picks'][i]))
    }

  }
}


class MemberInfo {
  constructor(data) {
    this.balance = data['balance']
    this.wallet = data['wallet']
  }
}

export class User {

  constructor(data) {

    this.first_name = data['first_name']
    this.last_name = data['last_name']
    this.email = data['email']
    this.username = data['username']
    this.member = new MemberInfo(data['member'])
    this.tickets = []
    this.pending_ticket_count = 0

    for (var i = 0; i < data['tickets'].length; i++) {
      let ticket = new Ticket(data['tickets'][i])
      this.tickets.push(ticket)

      if (ticket.status == 'open')
        this.pending_ticket_count += 1
    }

  }

}

class TicketOdd {
  constructor(data) {
    this.id = data['odd_id']
    this.match = new Match(data['match']['league'], data['match'])
    this.value = data['odd']
    this.status = data['status']
    this.item = new Item(this.match.local_team, this.match.visitor_team, data['item'])
    this.bet_type = new BetType(this.match.local_team, this.match.visitor_team, data['bet_type'], this.item['param'])
    this.bet_group = new BetGroup(data['bet_group'])
  }
}


export class TransactionWrapper {

  constructor(data) {
    this.transactions = []

    for (let transaction_data in data) {
      this.transactions.push(new Transaction(transaction_data))
    }

  }

  add = (transaction_data) => {
    this.transactions.unshift(new Transaction(transaction_data))
  }
}

class Transaction {

  constructor(data) {
    this.id = data['id']
    this.description = data['description']
  }

}

class WinOdd {
  constructor(local_team, visitor_team, data) {
    this.id = data['id']
    this.odd = data['odd']
    this.is_available = data['is_available']
    this.value = this.odd
    this.bet_group = new BetGroup(data['bet_group'])
    this.bet_type = new BetType(local_team, visitor_team, data['bet_type'])

    this.is_locked = !this.odd || data['result'] == 0 || data['result'] == 1

  }
}
class Team {
  constructor(data) {
    //this.badge = data['badge']
    //this.id = data['id']
    //this.league = data['league']
    this.name = data['name'] ? data['name'] : ''
  }
}
class Match {
  constructor(league, data) {
    this.id = data['id']

    this.local_team = data['hom']
    this.local_team_locale = data['hom']

    this.hom = data['hom']
    this.visitor_team = data['awy']
    this.visitor_team_locale = data['awy']

    this.awy = data['awy']
    this.expired = data['expired']
    this.schedule = data['schedule']
    this.win_odds = []
    this.item_count = data['item_count']
    this.league = league

    if (data['win_odds']) {
      for (var i = 0; i < data['win_odds'].length; i++) {
        this.win_odds.push(new WinOdd(this.local_team, this.visitor_team, data['win_odds'][i]))
      }
    }
  }
}


export class LeagueNoMatch {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.league_group = data.league_group
    this.sport_type = data.sport_type
    this.order = data.order
  }
}
class League {
  constructor(data) {
    this.rawData = data
    this.id = data['id']
    this.name = data['name']
    this.league_group = data['league_group']
    //this.league_groupobj = new leagueGroup(data['league_group']['sport_type'], data['league_group'])
    this.sport_type = data['sport_type']
    this.matches = []
    this.order = data['order']

    if (Array.isArray(data['matches'])) {
      for (var i = 0; i < (data['matches']).length; i++) {
        this.matches.push(new Match(this, data['matches'][i]))
      }
    }

  }
}

export class LastMinuteMatche {
  constructor(matches) {

    this.matches = []

    if (Array.isArray(matches)) {
      for (var i = 0; i < matches.length; i++) {
        this.matches.push(new Match(this, matches[i]))
      }
    }

  }
}

class leagueGroup {
  constructor(sport_type, data) {
    this.id = data['id']
    this.name = data['name']
    this.sport_type = sport_type
    this.leagues = []

    if (Array.isArray(data['leagues'])) {
      for (var i = 0; i < data['leagues'].length; i++) {
        let league = new League(data['leagues'][i])
        this.leagues.push(league)
        SportType.leagues[league.id] = league
      }
    }
    this.leagues.sort((league1, league2) => league1.order - league2.order)

  }
}

export class SportType {
  constructor(data) {
    this.name = data['name'];
    this.id = data['id'];


  }
}

SportType.leagues = {}
SportType.league_groups = {}

class BetType {
  constructor(local_team, visitor_team, data, param) {


    this.id = data['id']
    this.bet_group = data['bet_group']
    this.param = param
    this.order = data['order']
    this.local_team = local_team
    this.visitor_team = visitor_team
    this.name = this.formatName(this.formatName(data['name']))
    this.type = data['name']
    this.locales = data['locales']

  }

  formatName = (name => {
    return name.replace('{draw}', 'Draw').replace('{home}', this.local_team).replace('{away}', this.visitor_team).replace('{param}', this.param)
  })
}

export class BetGroup {
  constructor(data, param) {
    this.param = param
    this.id = data['id']
    this.order = data['order']
    this.hasParam = data['hasParam']
    this.name = this.formatName(data['name'])
    this.template = data['template']
    this.locales = data['locales'] || []
  }

  formatName = (name => {
    if (this.hasParam)
      return name.replace('{param}', this.param)

    return name
  })

}

BetGroup.HORIZONTAL = 'horizontal'
BetGroup.THREEWAY = 'threeway'
BetGroup.TWOWAY = 'twoway'
BetGroup.MATCHODD = 'matchodd'
BetGroup.HORIZONTALSTACK = 'horizontalstack'

class ItemWrapper {
  constructor(data) {
    this.bet_group = new BetGroup(data['bet_group'])
    this.items = []
    this.add = this.add.bind(this)
    this.id = data['id']
    this.param = data['param']
    this.specifier = data['specifier']
  }

  add(item) {
    this.items.push(item)
  }

  sort = () => {
    this.items.sort((item1, item2) => (parseFloat(item1.param) - parseFloat(item2.param)) || 0)
  }

}

class Odd {
  constructor(local_team, visitor_team, param, data) {
    this.id = data['id']
    this.value = data['odd']
    this.result = data['result']
    this.is_available = data['is_available']
    this.bet_type = new BetType(local_team, visitor_team, data['bet_type'], param)
    this.bet_group = new BetGroup(data['bet_group'], param)

    bet_groups[this.bet_group.id] = this.bet_group
  }
}

class Item {

  constructor(local_team, visitor_team, data) {

    this.param = data['param']
    this.specifier = data['specifier']
    this.odds = []
    this.bet_group = new BetGroup(data['bet_group'], this.param)
    this.id = data['id']
    let data_odds = data['odds']

    data_odds.sort((odd1, odd2) => (odd1.bet_type.order - odd2.bet_type.order))

    for (var i = 0; i < data_odds.length; i++) {
      this.odds.push(new Odd(local_team, visitor_team, this.param, data_odds[i]))
    }

  }

  getIdentifier() {

    let identifier = `${this.bet_group.id}`

    if (this.bet_group.hasParam) {
      Object.keys(this.specifier).forEach(key => {
        identifier += `${key}=${this.specifier[key]}`
      })
    }

    return identifier

  }

}
export class PrematchDetail {

  constructor(match_id, data) {

    this.id = data['id']
    this.local_team = data['hom']
    this.hom = data['hom']
    this.visitor_team = data['awy']
    this.awy = data['awy']

    this.awayteam_locale = data['awayteam_locale']
    this.hometeam_locale = data['hometeam_locale']
    this.schedule = data['schedule']

    this.items = []
    this.match = new Match(data['league']['id'], data)
    this.leagueobj = new League(data['league'])
    this.league = data['league']['id']
    this.item_wrappers = []
    let item_wrappers_dict = {}

    for (let i = 0; i < data['items'].length; i++) {
      let currentWrapper = null
      let item = new Item(this.local_team, this.visitor_team, data['items'][i])

      if (!(item.getIdentifier() in item_wrappers_dict))
        currentWrapper = item_wrappers_dict[item.getIdentifier()] = new ItemWrapper(item)

      else
        currentWrapper = item_wrappers_dict[item.getIdentifier()]

      if (currentWrapper)
        currentWrapper.add(item)
    }

    this.item_wrappers = Object.values(item_wrappers_dict)
    this.item_wrappers.sort((item1, item2) => item1.bet_group.order - item2.bet_group.order)
    this.item_wrappers.forEach(itemWrapper => {
      itemWrapper.sort()
    })

  }

}

export class PrematchOffer {

  constructor() {
    this.sport_types = {}
    this.league_groups = {}
    this.leagues = {}
    this.matches = []
    this.last_minutes = []
    this.highlights = []
    this.bet_types = {}
    this.bet_groups = {}
    this.locales = {}

    this.sportTypeLeagueGroup = {}
    this.leagueGroupLeague = {}
    this.leagueMatches = {}

    this.top_bets = []
    this.markFilters = {}

    this.coredata_loaded = false
    this.matches_loaded = false

  }

  load_coredata(data) {

    this.time = moment(data['time'])
    this.coredata_loaded = true
    this.last_minutes = []
    this.top_bets = []
    this.sport_types = {}
    this.league_groups = {}
    this.leagues = {}
    this.sportTypeLeagueGroup = {}
    this.leagueGroupLeague = {}

    for (var i = 0; i < data['locales'].length; i++)
      this.locales[data['locales'][i]['id']] = data['locales'][i]


    for (var i = 0; i < data['sport_types'].length; i++) {
      let sport_type = data['sport_types'][i]

      this.sport_types[sport_type['id']] = sport_type
    }

    for (var i = 0; i < data['bet_types'].length; i++) {
      let bet_type = data['bet_types'][i]
      this.bet_types[bet_type['id']] = bet_type
    }

    for (var i = 0; i < data['bet_groups'].length; i++) {
      this.bet_groups[data['bet_groups'][i]['id']] = data['bet_groups'][i]
    }

    for (var i = 0; i < data['last_minutes'].length; i++) {
      this.last_minutes.push(data['last_minutes'][i])
    }

    for (var i = 0; i < data['highlights'].length; i++) {
      this.highlights.push(data['highlights'][i])
    }

    for (var i = 0; i < data['league_groups'].length; i++) {
      let league_group = data['league_groups'][i]
      league_group.match_count = 0
      this.league_groups[league_group['id']] = league_group

      if (!this.sportTypeLeagueGroup[league_group['sport_type']])
        this.sportTypeLeagueGroup[league_group['sport_type']] = []

      this.sportTypeLeagueGroup[league_group['sport_type']].push(league_group)
    }


    for (var i = 0; i < data['leagues'].length; i++) {
      let league = data['leagues'][i]
      this.leagues[league['id']] = league
      let league_group = this.league_groups[league['league_group']]

      if (league_group)
        league_group.match_count += league.match_count


      if (!this.leagueGroupLeague[league['league_group']])
        this.leagueGroupLeague[league['league_group']] = []

      this.leagueGroupLeague[league['league_group']].push(league)

    }


    for (var i = 0; i < data['top_bets'].length; i++) {
      let top_bet = data['top_bets'][i]

      //if ( this.leagues[top_bet['league']])
      this.top_bets.push(top_bet['league'])
    }

    for (var i = 0; i < (data['market_filters'] || []).length; i++) {
      let market_filter = data['market_filters'][i]
      if (!this.markFilters[market_filter['filter_type']]) {
        this.markFilters[market_filter['filter_type']] = []
      }

      this.markFilters[market_filter['filter_type']].push(market_filter['bet_group'])
    }


  }

  load_matches(matches) {
    this.formatMatches(matches)
  }
  formatMatches = (matches) => {
    this.matches_loaded = true
    this.matches = []
    this.leagueMatches = []
    for (var i = 0; i < matches.length; i++) {
      let match = matches[i]

      this.matches.push(matches[i])

      if (!this.leagueMatches[match['league']])
        this.leagueMatches[match['league']] = []

      this.leagueMatches[match['league']].push(match)

    }
  }

  retrieveMatches = (lang) => {
    if (!lang)
      lang = 'en'

    Api.getData(endpoints['matches'], null, `ln=${lang}`)
      .then(events => this.formatMatches(events), () => { })
  }

  formatLastMinutes = (events) => {
    this.last_minutes = []
    for (var i = 0; i < events.length; i++) {
      this.last_minutes.push(events[i])
    }
  }

  retrieveLastMinutes = (lang) => {
    if (!lang)
      lang = 'en'

    Api.getData(endpoints["last-minutes"], null, `ln=${lang}`)
      .then(events => this.formatLastMinutes(events), () => { })
  }

}

export class FormatEntity {
  static HOME = "{$competitor1}"
  static AWAY = "{$competitor2}"

  static replaceAll = (data, search, replacement) => {
    return data.split(search).join(replacement)

  }

  static parseLegacySpecifier = (specifiers) => {
    if (!specifiers || (typeof specifiers != "string"))
      return []

    specifiers = specifiers.split("|")

    specifiers = specifiers.map(specifier => {
      specifier = specifier.split("=")
      return { name: specifier[0], value: specifier[1] }
    })


    return specifiers || []


  }

  static replaceVariablesForLegacySpecifier = (specifiers, data, event, lang) => {
    specifiers = FormatEntity.parseLegacySpecifier(specifiers)


    specifiers.forEach(specifier => {
      data = FormatEntity.replaceAll(data, `{${specifier.name}}`, specifier.value)
    })

    if (event) {
      let home_ = event.hom && ConstFunction.translate_match(event, lang, 'home') || event
      let away_ = event.awy && ConstFunction.translate_match(event, lang, 'away') || event

      data = FormatEntity.replaceAll(data, FormatEntity.HOME, home_)
      data = FormatEntity.replaceAll(data, FormatEntity.AWAY, away_)
    }

    return data

  }

  static replaceVariablesForSpecifier = (org_specifiers, data, event, lang) => {
    let specifiers = Object.keys(org_specifiers || {}).map(key => ({ name: key, value: org_specifiers[key] }))
    let nth = (n) => (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th")

    specifiers.forEach(specifier => {
      data = FormatEntity.replaceAll(data, `{${specifier.name}}`, specifier.value)
      data = FormatEntity.replaceAll(data, `{+${specifier.name}}`, `${specifier.value}`)
      data = FormatEntity.replaceAll(data, `{-${specifier.name}}`, `${specifier.value}`)

    })

    specifiers.forEach(specifier => {

      let value = specifier.value

      if (Number.isInteger(parseFloat(value)))
        value = `${value}${nth(parseInt(value))}`

      data = FormatEntity.replaceAll(data, `{!${specifier.name}}`, value)

    })

    if (event) {
      let home_ = event.hom && ConstFunction.translate_match(event, lang, 'home') || event
      let away_ = event.awy && ConstFunction.translate_match(event, lang, 'away') || event

      data = FormatEntity.replaceAll(data, FormatEntity.HOME, home_)
      data = FormatEntity.replaceAll(data, FormatEntity.AWAY, away_)
    }


    data = this.replaceVariablesForLegacySpecifier(org_specifiers, data, event, lang)


    return data

  }

  static formatBetTypeame = (name, event, param, lang = 'en') => {
    return FormatEntity.replaceVariablesForSpecifier(param, name, event, lang)
  }

  static formatBetGroupame = (name, param, event, lang = 'en') => {

    return FormatEntity.replaceVariablesForSpecifier(param, name, event, lang = 'en')

  }

}


export class PrintDataLineBreakRow {
  constructor() {
    this.type = 'linebreak'
  }

  to_representation = () => {

    return {
      type: this.type,
    }

  }

}

export class PrintDataDoubleLineBreakRow {
  constructor() {
    this.type = 'doublelinebreak'
  }

  to_representation = () => {

    return {
      type: this.type,
    }

  }

}

export class PrintDataWhiteSpaceBreakRow {
  constructor() {
    this.type = 'whitespacebreak'
  }

  to_representation = () => {

    return {
      type: this.type,
    }

  }

}

export class PrintDataBarCodeRow {

  constructor(barcode_value) {

    this.type = 'barcode'

    this.meta = {
      'value': barcode_value,
      'height': 30,
      'width': 2
    }

  }


  setSize = (height, width) => {
    this.meta['height'] = height
    this.meta['width'] = width
  }

  to_representation = () => {

    return {
      type: this.type,
      meta: this.meta
    }

  }

}

export class PrintDataDateBranchRow {
  constructor() {
    this.type = 'datebranch'
  }

  to_representation = () => {

    return {
      type: this.type,
    }

  }
}

export class PrintDataReceiptBranchRow {
  constructor(receipt) {
    this.type = 'branchreceipt'
    this.receipt = receipt
  }

  to_representation = () => {

    return {
      type: this.type,
      receipt: this.receipt
    }

  }
}

export class PrintDataDateRow {
  constructor() {
    this.type = 'date'
  }

  to_representation = () => {

    return {
      type: this.type,
    }

  }
}

export class PrintDataDateMinuteRow extends PrintDataDateRow {
  constructor() {
    super()
    this.type = 'dateminute'
  }

}

export class PrintDataDateSecondRow extends PrintDataDateRow {
  constructor() {
    super()
    this.type = 'datesecond'
  }

}

export class PrintDataColumn {

  constructor(value) {

    this.value = value

  }

  to_representation = () => {

    return {
      label: this.label,
      value: this.value,
    }

  }

}

export class PrintDataRow {

  constructor() {
    this.columns = []
    this.type = 'data'//Refers to key value type of data
    this.meta = {
      'emphasis': 'no',
      'mode': 'single', //single, doublewidth, doubleheight, doublewidthheight
    }

  }

  addColumn = (column) => {
    this.columns.push(column.to_representation())
  }

  set_emphasis = (emphasis) => {
    this.meta['emphasis'] = emphasis
  }

  set_mode = (mode) => {
    this.meta['mode'] = mode
  }

  to_representation = () => {

    return {
      type: this.type,
      columns: this.columns,
      meta: this.meta,
    }

  }

}
export class PrintData {

  constructor() {
    this.meta = { 'font': 'A', 'color': 0, 'linespace': 1, 'paper_size': 48 }
    this.header = { 'rows': [] }
    this.body = { 'rows': [] }
    this.footer = { 'rows': [] }
  }


  addHeaderRow = (printDataRow) => {
    this.header['rows'].push(printDataRow.to_representation())

  }

  addBodyRow = (printDataRow) => {
    this.body['rows'].push(printDataRow.to_representation())
  }

  addBodyBodyGamePicks = (gamepicks) => {
    this.body['gamepicks'] = gamepicks
  }

  addFooterRow = (printDataRow) => {
    this.footer['rows'].push(printDataRow.to_representation())
  }

  to_representation = () => {

    return {
      meta: this.meta,
      data: {
        header: this.header,
        body: this.body,
        footer: this.footer
      }
    }

  }

}

export default {
  PrematchDetail: PrematchDetail,
  PrematchOffer: PrematchOffer,
  ConstFunction: ConstFunction,
  Ticket: Ticket,
  OfflineTicket: OfflineTicket,
  User: User,
  TransactionWrapper: TransactionWrapper,
  LastMinuteMatche: LastMinuteMatche,
  FormatEntity: FormatEntity,
  PrintDataColumn: PrintDataColumn,
  PrintDataRow: PrintDataRow,
  PrintData: PrintData
}