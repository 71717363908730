import React from 'react'
//import {Spinner} from 'reactstrap'
// import { Button } from 'antd'
import './loading.css';
import Constants from './components/constants';

export default function DotFalling(props) {

  return (

    <div style={{ background: Constants.CONFIG.LOADING_BACKGROUND }} className='loading-home'>

      {props.try_again &&
        (<div className='match-detail-loading-main'>

          <div className='loading-message'> Failed to retrieve data. Please try again.</div>
          <button className='loading-home-tryagain' onClick={props.try_again} >Try again</button>
        </div>) ||

        <div className='match-detail-loading-main'>
          
          <div><img style={{ maxWidth : "200px"}} src={`/${process.env.REACT_APP_LOADING_LOGO}`} /> </div>
          <br />
          <svg style={{ filter :  "brightness(0) invert(1)" }} class="fill-current" width="60" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="60" cy="15" r="9" fill-opacity="0.3"><animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="105" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>     
        </div>}

    </div>

  )

}