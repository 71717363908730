/**
 * Created by pc on 4/7/18. */
import axios from "axios";
import ClientSession from "./client-session.js";
import Constants from "../components/constants.js";

//let API_BASE = "http://localhost:8000"
//let API_BASE = "http://192.168.43.46:8000"
let API_BASE = Constants.CONFIG.API_BASE_URL
let API_BASE_URL = API_BASE + "/"
let API_BRANCH_URL = Constants.CONFIG.API_BRANCH_URL


export default class Api {

  static API_BASE_URL = API_BASE_URL;
  static API_BASE = API_BASE;

  static AGENTSESSION = 'agentsession'
  static AGENTSESSION = 'agentsession'

  static handleInvalidToken(error) {
    if (error.response && error.response.status == 403 && error.response.data && error.response.data.detail) {

      if (error.response.data.detail.toLowerCase().indexOf('invalid token') != -1) {
        ClientSession.removeAuth(() => { }, Api.AGENTSESSION)
        ClientSession.removeAuth(() => { })
      }

    }

  }

  static getData(method, sessionID, param) {

    let url = API_BASE_URL + method + '/'

    if (param)
      url += `?${param}`

    return new Promise(function (resolve, reject) {

      ClientSession.getAccessToken(function (isLoggedIn, authData) {

        let headers = {
          withCredentials: false,
        }

        if (isLoggedIn && authData != null) {

          headers = {
            withCredentials: false,
            'headers': {
              Authorization: 'Token ' + authData.token
            }
          }

        }

        axios
          .get(url, headers)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {

            Api.handleInvalidToken(error)
            reject(error);
          });

      }, sessionID);//clientSession 

    });//promise

  }


  static SalesBranch() {
    return new Promise(function (resolve, reject) {
      axios
        .get(API_BRANCH_URL, { withCredentials: false })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static agentGetData(method) {
    return Api.getData(method, Api.AGENTSESSION)
  }

  static postData(method, data, sessionID, not_format_error) {
    let url = API_BASE_URL + method + '/'

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {

        let headers = {
          withCredentials: false,
        }

        if (isLoggedIn && authData != null) {

          headers = {
            withCredentials: false,
            'headers': {
              Authorization: 'Token ' + authData.token
            }
          }

        }

        axios
          .post(url, data, headers)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            Api.handleInvalidToken(error)

            if (error.response && error.response.data) {
              if (not_format_error)
                reject(error.response.data)

              else
                reject(Api.formatError(error.response.data))

            }
            else
              reject(String(error))

          })

      }, sessionID)


    })
  }

  static putData(method, data, sessionID) {
    let url = API_BASE_URL + method + '/'

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {

        let headers = {
          withCredentials: false,
        }

        if (isLoggedIn && authData != null) {

          headers = {
            withCredentials: false,
            'headers': {
              Authorization: 'Token ' + authData.token
            }
          }

        }

        axios
          .put(url, data, headers)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            Api.handleInvalidToken(error)

            if (error.response && error.response.data)
              reject(Api.formatError(error.response.data))
            else
              reject(String(error))

          })

      }, sessionID)


    })
  }

  static agentPostData(method, data) {
    return Api.postData(method, data, Api.AGENTSESSION)
  }

  static agentPutData(method, data) {
    return Api.putData(method, data, Api.AGENTSESSION)
  }

  static formatError(errors) {
    if (!errors)
      return;


    for (let error_key in errors) {
      return String(errors[error_key])
    }

    return null
  }

  static login(auth_data) {
    return Api.generalLogin(auth_data, null, 'member-login', true)
  }

  static agentLogin(auth_data) {
    return Api.generalLogin(auth_data, Api.AGENTSESSION, null, true)
  }

  static agentLogout(callback) {
    ClientSession.removeAuth(callback, Api.AGENTSESSION)
  }

  static generalLogin(auth_data, sessionID, endPoint, format_error) {

    if (!endPoint)
      endPoint = 'my-login'

    return new Promise(function (resolve, reject) {

      Api.postData(endPoint, auth_data, null, format_error)
        .then((response) => {

          if (response['token']) {
            ClientSession.storeAuth(response, (err) => { }, sessionID)

            resolve(response)

          }

        }, error => {
          reject(error)
        })
    })
  }

  static HandleAuthTOken(response) {
    if (response && response['token']) {
      ClientSession.storeAuth(response, (err) => { })

    }
  }

  static memberRegister(register_data) {

    return new Promise(function (resolve, reject) {

      Api.postData('register', register_data)
        .then((response) => {

          resolve(response)

        }, error => {
          reject(error)
        })
    })


  }

  static memberPhoneVerify(code_data) {

    return new Promise(function (resolve, reject) {

      Api.postData(`phoneverify`, code_data)
        .then((response) => {

          if (response['token']) {
            ClientSession.storeAuth(response, (err) => { }, null)

            resolve(response)

          }

        }, error => {
          reject(error)
        })
    })


  }

  static resetPassword(reset_data) {

    return new Promise(function (resolve, reject) {

      Api.postData('forgotpassword.reset', reset_data)
        .then((response) => {

          if (response['token']) {
            ClientSession.storeAuth(response, (err) => { }, null)

            resolve(response)

          }

        }, error => {
          reject(error)
        })
    })


  }


}

