import React from 'react'
//import {Spinner} from 'reactstrap'
// import { Button } from 'antd'
import './loading.css';
import Constants from './components/constants';

export default function HeartLoad(props) {

  return (

    <div style={{ background: Constants.CONFIG.LOADING_BACKGROUND }} className='loading-home'>

      {props.try_again &&
        (<div className='match-detail-loading-main'>

          <div className='loading-message'> Failed to retrieve data. Please try again.</div>
          <button className='loading-home-tryagain' onClick={props.try_again} >Try again</button>
        </div>) ||

        <div className='match-detail-loading-main'>
          <div className='heart'>

            {/* {props.main_configuration && <img
              style={{ marginBottom: 35, width: Constants.CONFIG.LOADING_LOGO_W }}
              alt='loadingimages'
              src={props.main_configuration['loading_logo']} />} */}
          </div>
          <div>Just few seconds </div>
          <img  style={{ marginBottom: 35, width: Constants.CONFIG.LOADING_LOGO_W }} src={`/${process.env.REACT_APP_LOADING_LOGO}`} />
        </div>}

    </div>

  )

}