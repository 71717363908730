import React from 'react'
import Constants from './components/constants';
import DotFalling from './dotfallingload';
import HeartLoad from './HeartLoad';
//import {Spinner} from 'reactstrap'
// import { Button } from 'antd'
import './loading.css';
import NormalLoad from './NormalLoad';

let loadType = Constants.CONFIG.LOADING_TYPE

export default function HomeLoad(props) {

  if (loadType == 'heart')
    return <HeartLoad
      main_configuration={props.main_configuration} props={props} />
  if ( loadType == 'dotfalling')
    return <DotFalling  main_configuration={props.main_configuration} props={props} />
    
  return <NormalLoad
    main_configuration={props.main_configuration} props={props} />

}