import Constants from "../components/constants"

let legacy_endpoints = {
    "user-detail": "online/user-detail",
    "core-data": "legacy-data/coredata",
    "matches": "legacy-data/matches",
    "bet-place": "legacy-data/bet.place",
    "match-search": "legacy-data/matches.search",
    "match-detail": "legacy-data/matches",
    "last-minutes": "legacy-data/matches/lastminutes",
    "accumulator": "accumilator.custom",
    "onlineagent-bet-place": "online-agent/bet-place",
}

let betradar_endpoints = {
    "user-detail": "online/user-detail",
    "core-data": "sport-data/coredata",
    "matches": "sport-data/matches",
    "bet-place": "sport-data/bet.place",
    "match-search": "sport-data/matches.search",
    "match-detail": "sport-data/matches",
    "last-minutes": "sport-data/matches/lastminutes",
    "accumulator": "sport-data/accumilator",
    "onlineagent-bet-place": "online-agent/bet-place",

}

export default {
    [Constants.DATA_TYPE_SPORTDATA]: betradar_endpoints,
    [Constants.DATA_TYPE_LEGACY]: legacy_endpoints
}[Constants.CONFIG.ENDPOINT_TYPE]
